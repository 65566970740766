//
// Modal
//

.modal {
  --#{$prefix}modal-color: var(--kt-modal-content-color);
  --#{$prefix}modal-bg: var(--kt-modal-content-bg);
  --#{$prefix}modal-border-color: var(--kt-modal-content-border-color);
  --#{$prefix}modal-box-shadow: var(--kt-modal-content-box-shadow-xs);
  --#{$prefix}modal-header-border-color: var(--kt-modal-header-border-color);
  --#{$prefix}modal-footer-bg: var(--kt-modal-footer-bg);
  --#{$prefix}modal-footer-border-color: var(--kt-modal-footer-border-color);
}

// Mobile dialog
.modal-dialog {
  outline: none !important;
}

// Modal header
.modal-header {
  align-items: center;
  justify-content: space-between;
  @include border-top-radius($modal-content-border-radius);
  border-bottom: $modal-header-border-width solid var(--kt-modal-header-border-color);

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

// Modal content
.modal-content {
  color: var(--kt-modal-color);
  background-color: var(--kt-modal-bg);
  border: $modal-content-border-width solid var(--kt-modal-border-color);
  @include box-shadow(var(--kt-modal-box-shadow));
}

// Modal footer
.modal-footer {
  background-color: var(--kt-modal-footer-bg);
  border-top: $modal-footer-border-width solid var(--#{$prefix}modal-footer-border-color);
}

// Modal background
.modal-backdrop {
  --#{$prefix}backdrop-bg: var(--kt-modal-backdrop-bg);
  --#{$prefix}backdrop-opacity: var(--kt-modal-backdrop-opacity);
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  .modal-content {
    @include box-shadow(var(--kt-modal-box-shadow-sm-up));
  }
}

// Utilities
.modal-rounded {
  @if $enable-rounded {
    border-radius: $modal-content-border-radius !important;
  }
}

.modal-content {
  box-shadow: var(--kt-modal-box-shadow-sm-up);
  width: 650px;
  height: 330px;
  margin-top: 132px;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  outline: 0;
  color: rgb(253, 250, 246);
  background-color: #141414 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  color: azure;
  border-radius: 4px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 32px;
  color: azure;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  background-color: brown;
}

.podding {
  min-width: 0%;
  border-color: rgb(234 219 219);
}

.custom-card-body {
  background-color: #141414 !important;
}
.css-1e6ud36-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1e6ud36-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #ef1818;
}
.css-px39cz-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: #fff;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  border-radius: 4px;
  margin-left: 33px;
}
.css-px39cz-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #db7100;
  border-width: 2px;
}
