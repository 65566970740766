//
// Footer
// 

.footer {
    background-color: var(--kt-footer-bg-color);
    border-left:1px solid #000;
}

.text-center {
    text-align: center;
}