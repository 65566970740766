.name {
  color: rgb(248, 242, 242);
  font-size: 18px;
  border-color: #db7100;
  margin-top: 40px;
  background: #282626;
  border-radius: 5px;
  padding: 10px;
  margin: 20px;
  margin-left: -5px;
}
.name {
  color: rgb(248, 242, 242);
  font-size: 10px;
  border-color: #594214;
  background: #312f2d;
  border-radius: 5px;
  padding: 10px;
  margin: 20px;
  margin-left: -5px;
}
.css-px39cz-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #db7100;
    border-width: 2px;
  }
  .pod{
    color: #f0eeeb;
    margin-left: 30px;
  }
