.blockquote-footer {
  color: var(--kt-blockquote-footer-color);
}

.text-orange {
	color:$color-orange;
}
.text-light-white {
  color:$light-white;
}
.text-grey-white {
  color:$grey-white;
}
.bg-orange {
background-color: $background-orange;
}
.ant-tag-gold {
  color: #d89614;
  background: #2b2111;
  border-color: #594214;
  font-weight: 500;
}