//
// Main
//

// Body
body {
  background-color: $black;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  @media (min-width: 1400px){
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 99%;
  }  
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, fixed, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      padding-top: calc(
        #{get($header-config, fixed, height, tablet-and-mobile)} + var(--kt-toolbar-height-tablet-and-mobile)
      );
    }
  }
}

// -- Data Grid CSS Start ==

// .dx-datagrid .dx-column-lines > td{
//   border-left: none; 
//   border-right: none;
// }

// .dx-texteditor.dx-editor-outlined {
//   background: #2a2a2a;
//   border: 1px solid #4d4d4d;
//   border-radius: 4px;
//   border-color: orange !important;
// }

// .dx-datagrid-focus-overlay {
//   position: absolute;
//   pointer-events: none;
//   border-color: orange !important;
//   top: 0;
//   left: 0;
//   visibility: hidden;
// }
// .dx-editor-cell .dx-focused{
//   border-color: red;
// }

// .dx-datagrid-headers .dx-texteditor-input, .dx-datagrid-rowsview .dx-texteditor-input {
//   padding: 20px;
//   min-height: 50px;
 
// }

// .dx-texteditor.dx-state-hover {
//   border-color: #d87a16 !important;
// }
// .dx-editor-cell dx-focused{
//   border-color: #d87a16 !important;
// }
// td.dx-editor-cell.dx-focused {
//   border-color: #d87a16;
// }
// .dx-texteditor.dx-state-active.dx-editor-filled,
// .dx-texteditor.dx-state-active.dx-editor-outlined,
// .dx-texteditor.dx-state-active.dx-editor-underlined,
// .dx-texteditor.dx-state-focused.dx-editor-filled,
// .dx-texteditor.dx-state-focused.dx-editor-outlined,
// .dx-texteditor.dx-state-focused.dx-editor-underlined{
//   border-color: 'none'!important;
// }
// .dx-texteditor.dx-state-focused.dx-editor-outlined,.dx-texteditor.dx-state-focused.dx-editor-outlined{
// border-color: #d87a16;
// }

// .dx-datagrid{  
//     font:9px verdana;  
// } 
// .dx-widget{
//   font-family: "Segoe UI Regular";
// }