//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    // padding: get($content-spacing, desktop) 0;

    // Toolbar enabled and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-fixed) & {
      padding-top: 0;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    // padding: get($content-spacing, tablet-and-mobile) 0;

    // Toolbar enabled mode and toolbar not fixed mode
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      padding-top: 0;
    }
  }
}

.container,
.container-xxl,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

}
// .dx-datagrid-focus-overlay:after {
//   background-color: #db7100 !important;
// }