//
// Breadcrumb
//

// Breadcrumb
.breadcrumb {
  --#{$prefix}breadcrumb-bg: var(--kt-breadcrumb-bg);
  --#{$prefix}breadcrumb-divider-color: var(--kt-breadcrumb-divider-color);
  --#{$prefix}breadcrumb-item-active-color: var(--kt-breadcrumb-item-active-color);

  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;

  // Item breadcrumb
  .breadcrumb-item {
    color: #FFFFFFD9 !important;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 0;
    padding-right: $breadcrumb-item-padding-x;

    &:last-child {
      padding-right: 0;
    }

    &:after {
      content: '/';
      //padding-left: $breadcrumb-item-padding-x;
    }

    &:before {
      display: none;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

// Breadcrumb line style
.breadcrumb-line {
  .breadcrumb-item {
    &:after {
      content: '-';
    }
  }
}

// Breadcrumb dot style
.breadcrumb-dot {
  .breadcrumb-item {
    &:after {
      content: '\2022';
    }
  }
}

// Breadcrumb separatorless style
.breadcrumb-separatorless {
  .breadcrumb-item {
    &:after {
      display: none;
    }
  }
}
