//
// Nav
//

// Line tabs
.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--kt-border-color);

  .nav-item {
    margin-bottom: -1px;

    // Base link
    .nav-link {
      color: var(--kt-gray-500);
      border: 0;
      border-bottom: 1px solid transparent;
      transition: $transition-link;
      padding: 0.5rem 0;
      margin: 0 1rem;
    }

    // First Item
    &:first-child {
      .nav-link {
        margin-left: 0;
      }
    }

    // Last Item
    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }

  // Active & Hover States
  .nav-item .nav-link.active,
  .nav-item.show .nav-link,
  .nav-item .nav-link:hover:not(.disabled) {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--kt-primary);
    transition: $transition-link;
  }

  // 2x Line
  &.nav-line-tabs-2x {
    border-bottom-width: 2px;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        border-bottom-width: 2px;
      }
    }

    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border-bottom-width: 2px;
    }
  }
}



// .ant-tabs-tab {
//   position: relative;
//   display: inline-flex;
//   align-items: center;
//   // margin: 0 32px 0 0;
//   padding: 15px 0;
//   font-size: 14px;
//   // background: transparent;
//   border-radius: 10;
//   outline: none;
//   cursor: pointer;
//   color:orange;
// }
//  .ant-tabs-nav-wrap {
//   position: relative;
//   display: inline-block;
//   display: flex;
//   flex: auto;
//   align-self: stretch;
//   overflow: hidden;
//   white-space: nowrap;
//   -webkit-transform: translate(0);
//   transform: translate(0);
//   color:orange;
// }
// .nav-link {
//   display: block;
//   padding: 0.5rem 1rem;
//   font-family: 'Roboto';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 14px;
//   letter-spacing: 0.05em;
//   color: #404040;
// }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #DB7100;
    font-size: 11px;
    color: hsla(0,0%,100%,.85);
    font-weight: 700;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #404040;

}

.nav-link:hover,
.nav-link:focus {
    color: black;
}